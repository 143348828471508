import { Link, useNavigate, useOutletContext } from "@remix-run/react";
import { SimpleGrid, Text } from "@mantine/core";
import { Database } from "~/types/supabase";
import { ImageModalCarousel } from "~/components/_common/ImageModalCarousel/ImageModalCarousel";
import type { GalleryPhoto, OutletContext } from "~/types/shared";

import * as classes from "./ImageList.css";

export type DB = Database["public"]["Tables"];

export function ImageList({
  photos,
  selectedPhotoId,
  basePath,
  prefixPath,
  additionalMenuItems,
  uploadCTA,
}: {
  photos: GalleryPhoto[];
  selectedPhotoId: string | undefined;
  basePath: string;
  prefixPath?: string;
  additionalMenuItems?: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    loading?: boolean;
    disabled?: boolean;
    closeMenuOnClick?: boolean;
  }[];
  uploadCTA?: React.ReactNode;
}) {
  const { supabaseUrl } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  if (!photos.length) {
    return (
      <Text
        c="div"
        m="auto"
        size="md"
        style={{
          textAlign: "center",
        }}
      >
        <Text span mb="xl">
          No photos have been uploaded
        </Text>
        <span>{uploadCTA}</span>
      </Text>
    );
  }

  return (
    <SimpleGrid cols={{ base: 2, sm: 3, lg: 4 }} className={classes.grid}>
      {photos.map((image) => {
        return (
          <Link
            key={image.id}
            to={
              prefixPath
                ? `${basePath}/${prefixPath}/${image.id}`
                : `${basePath}/${image.id}`
            }
            className={classes.imageLink}
            preventScrollReset
          >
            <img
              className={classes.image}
              src={image.urls?.thumbnail}
              alt={""}
            />
          </Link>
        );
      })}

      {selectedPhotoId ? (
        <ImageModalCarousel
          imageBasePath={supabaseUrl}
          opened
          photos={photos}
          onClose={() => {
            navigate(basePath, { replace: true, preventScrollReset: true });
          }}
          basePath={prefixPath ? `${basePath}/${prefixPath}` : `${basePath}`}
          selectedPhotoId={selectedPhotoId}
          additionalMenuItems={additionalMenuItems}
        />
      ) : null}
    </SimpleGrid>
  );
}

import { useEffect, useRef, useState } from "react";
import { Loader, Modal, VisuallyHidden } from "@mantine/core";
import {
  Link,
  useFetcher,
  useNavigate,
  useOutletContext,
} from "@remix-run/react";
import {
  IconChevronLeft,
  IconChevronRight,
  IconDots,
  IconTrash,
} from "@tabler/icons-react";
import type { GalleryPhoto, OutletContext } from "~/types/shared";
import { ResponsiveImage } from "~/components/_common/ResponsiveImage/ResponsiveImage";
import { Menu, MenuItem } from "~/components/_common/Menu/Menu";
import { ButtonIcon } from "~/components/_common/ButtonIcon/ButtonIcon";
import { action } from "~/routes/_api.delete-photo-2";
import { showNotication } from "~/config/notifications";
import { vars } from "~/config/theme";
import * as classes from "./ImageModalCarousel.css";

function GalleryImage({
  imageProps,
  menuItems,
  isActive,
  fetchPriority,
}: {
  imageProps: GalleryPhoto;
  menuItems: React.ReactNode[];
  isActive: boolean;
  fetchPriority?: "high" | "low" | "auto";
}) {
  const [isLoading, setIsLoading] = useState(isActive);

  return (
    <div
      className={
        isActive ? classes.imageContainer : classes.imageContainerHidden
      }
    >
      <div className={classes.imageContainerInner}>
        {menuItems.length && !isLoading ? (
          <Menu
            position="bottom-end"
            button={
              <ButtonIcon className={classes.menuToggle} filled>
                <IconDots />
              </ButtonIcon>
            }
            items={[menuItems]}
          />
        ) : null}
        <ResponsiveImage
          image={imageProps.urls}
          alt={""}
          mediumMinWidth={600}
          largeMinWidth={800}
          className={classes.mainImage}
          onLoad={() => setIsLoading(false)}
          fetchPriority={fetchPriority}
        />
      </div>
      {isLoading ? (
        <Loader className={classes.loadingIcon} type="bars" color="white" />
      ) : null}
    </div>
  );
}

export function ImageModalCarousel({
  opened,
  onClose,
  photos,
  basePath,
  selectedPhotoId,
  additionalMenuItems = [],
}: {
  opened: boolean;
  onClose: () => void;
  photos: GalleryPhoto[];
  imageBasePath: string;
  basePath: string;
  selectedPhotoId: string;
  additionalMenuItems?: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    loading?: boolean;
    disabled?: boolean;
    closeMenuOnClick?: boolean;
  }[];
}) {
  const isClosing = useRef(false);
  const navigation = useNavigate();
  const { userProfile } = useOutletContext<OutletContext>();
  const deleteFetcher = useFetcher<typeof action>();
  const currentIndex = photos.findIndex(
    (image) => image.id === selectedPhotoId
  );

  const isDeleting = deleteFetcher.state !== "idle";

  const nextPhotoId = photos[currentIndex + 1]?.id || photos[0]?.id;
  const prevPhotoId =
    photos[currentIndex - 1]?.id || photos[photos.length - 1]?.id;

  const { data } = deleteFetcher;

  const { error, success } = data || {};

  const menuItemLoading = additionalMenuItems.some(({ loading }) => loading);

  useEffect(() => {
    if (error) {
      console.error(error);
      showNotication(error, "error");
    } else if (success && !isClosing.current) {
      isClosing.current = true;
      showNotication("Photo successfully deleted", "success");
      onClose();
    }
  }, [basePath, onClose, navigation, selectedPhotoId, error, success]);

  // previous and next photos renderered but hidden so they are preloaded
  const previousPhoto =
    currentIndex > 0 ? photos[currentIndex - 1] : photos[photos.length - 1];
  const nextPhoto =
    currentIndex < photos.length - 1 ? photos[currentIndex + 1] : photos[0];

  const currentPhoto = photos[currentIndex];
  const isActive = currentPhoto.id === selectedPhotoId;
  const isOwner = userProfile?.id === currentPhoto.owner_id;
  const menuItems = isOwner
    ? [
        ...additionalMenuItems.map(
          ({ onClick, icon, label, loading, disabled, closeMenuOnClick }) => {
            return (
              <MenuItem
                key={label}
                disabled={isDeleting || disabled}
                className={classes.menuItem}
                onClick={onClick}
                leftSection={icon}
                rightSection={
                  loading ? (
                    <Loader size="xs" color={vars.colors.snBlack60[0]} />
                  ) : (
                    <span className={classes.notLoading} />
                  )
                }
                closeMenuOnClick={closeMenuOnClick}
              >
                {label}
              </MenuItem>
            );
          }
        ),
        isOwner && (
          <MenuItem
            key="delete"
            disabled={isDeleting || menuItemLoading}
            closeMenuOnClick={false}
            className={classes.menuItem}
            onClick={() => {
              const formData = new FormData();
              const imageName = photos[currentIndex].name;
              formData.append("image_name", imageName!);
              formData.append("image_id", selectedPhotoId!);
              deleteFetcher.submit(formData, {
                method: "delete",
                action: "/delete-photo-2",
              });
            }}
            leftSection={<IconTrash />}
            rightSection={
              isDeleting && (
                <Loader size="xs" color={vars.colors.snBlack60[0]} />
              )
            }
          >
            Delete
          </MenuItem>
        ),
      ]
    : [];

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="auto"
      closeOnClickOutside={false}
      centered
      classNames={{
        content: classes.content,
        header: classes.header,
        body: classes.body,
        inner: classes.inner,
        overlay: classes.overlay,
      }}
    >
      <div className={classes.container}>
        <GalleryImage
          key={currentPhoto.id}
          imageProps={currentPhoto}
          isActive={isActive}
          menuItems={menuItems}
          fetchPriority="high"
        />
        <GalleryImage
          imageProps={previousPhoto}
          isActive={false}
          menuItems={[]}
          fetchPriority="low"
        />
        <GalleryImage
          imageProps={nextPhoto}
          isActive={false}
          menuItems={[]}
          fetchPriority="low"
        />
      </div>
      {prevPhotoId !== selectedPhotoId && (
        <Link
          replace
          preventScrollReset
          prefetch="render"
          className={classes.prevLink}
          to={`${basePath}/${prevPhotoId}`}
        >
          <IconChevronLeft size="36" />
          <VisuallyHidden>Previous photo</VisuallyHidden>
        </Link>
      )}
      {nextPhotoId !== selectedPhotoId && (
        <Link
          replace
          preventScrollReset
          prefetch="render"
          className={classes.nextLink}
          to={`${basePath}/${nextPhotoId}`}
        >
          <IconChevronRight size="36" />
          <VisuallyHidden>Next photo</VisuallyHidden>
        </Link>
      )}
    </Modal>
  );
}

import { Menu as MantineMenu } from "@mantine/core";
import * as classes from "./Menu.css";

export const MenuItem = MantineMenu.Item;
export const MenuLabel = MantineMenu.Label;
export const MenuDivider = MantineMenu.Divider;

export function Menu({
  button,
  items,
  ...rest
}: React.ComponentProps<typeof MantineMenu> & {
  button: React.ReactNode;
  items: React.ReactNode[];
}) {
  return (
    <MantineMenu
      {...rest}
      classNames={{
        dropdown: classes.dropdown,
        item: classes.item,
      }}
    >
      <MantineMenu.Target>{button}</MantineMenu.Target>
      <MantineMenu.Dropdown>{items}</MantineMenu.Dropdown>
    </MantineMenu>
  );
}
